import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Wrapper, Title, SubTitle, Contact } from "../styles/maintenanceStyles"

const MaintenancePage = () => (
  <Layout>
    <Seo title="Maintenance" />
    <Container>
        <Wrapper>
            <Title>We're Replacing <br/> Some Parts</Title>
            <SubTitle>Sorry for the inconvenience, we are under maintenance and we will be back soon.</SubTitle>
            <Contact>Feel free to contact us at {process.env.GATSBY_EMAIL}</Contact>
        </Wrapper>
    </Container>
  </Layout>
)

export default MaintenancePage


