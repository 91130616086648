import styled from "styled-components"
import BackImg from "../images/maintenance.png"

const Container = styled.div`
    background-color: #202620;
    color: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    min-height: 65vh;
    background-image: url(${BackImg});
    margin-bottom: 0px;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 80%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

`;

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 426px) {
        width: 90%;
      }
`;

const Title = styled.div`
    font-size: 66px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1px;
    line-height: 1;
    width: 100%;
    margin-bottom: 20px;
`;

const SubTitle = styled.div`
    font-size: 20px;
    color: #F2C738;
    letter-spacing: 1px;
    line-height: 1;
    width 45%;
`;

const Contact = styled.div`
    font-size: 20px;
    color: #F2C738;
    letter-spacing: 1px;
    line-height: 1;
    width 100%;
    margin-top: 12%;
`;
export {
    Container,
    Wrapper,
    Title,
    SubTitle,
    Contact
}